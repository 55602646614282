{
  "name": "bo-app",
  "version": "2.1.1",
  "scripts": {
    "build": "./scripts/version.sh && ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/material": "^18.0.0",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "rxjs": "^7.5.5",
    "tslib": "^2.4.0",
    "zone.js": "^0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.0",
    "@angular-eslint/builder": "^17.5.1",
    "@angular-eslint/eslint-plugin": "^17.5.1",
    "@angular-eslint/eslint-plugin-template": "^17.5.1",
    "@angular-eslint/template-parser": "^17.5.1",
    "@angular/cli": "^18.0.0",
    "@angular/compiler-cli": "^18.0.0",
    "@angular/material-luxon-adapter": "^18.0.0",
    "@sparbanken-syd/borgo-helpers": "^1.0.1",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@types/jasmine": "^4.0.3",
    "@types/jmespath": "^0",
    "@types/karma-spec-reporter": "^0",
    "@types/luxon": "^3.4.2",
    "@types/node": "20",
    "@types/sparbanken-syd-borgo": "^3.0.1",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "eslint": "^8.53.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.2.5",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "^5.1.2",
    "jmespath": "^0.16.0",
    "karma": "^6.4.3",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-spec-reporter": "^0.0.36",
    "luxon": "^3.4.4",
    "postcss": "^8.4.38",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.5.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "stylelint-scss": "^6.3.0",
    "typescript": "^5.4.5"
  }
}
