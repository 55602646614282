import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {
  AUTOGIRO_ROUTE_PATH,
  DOCUMENTS_ROUTE_PATH,
  KYC_ROUTE_PATH,
  LETTER_ROUTE_PATH,
  LOAN_ROUTE_PATH,
  START_ROUTE_PATH
} from './application/data-types'
import {CodeComponent} from './common/code/code.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: START_ROUTE_PATH
  },
  {
    /**
     * Very special path. That snoops the '#' from the url
     * sends the code to be and then routes to "documents"
     */
    path: 'code',
    component: CodeComponent
  },
  {
    path: START_ROUTE_PATH,
    loadChildren: () => import('./0-start/start.module').then(m => m.StartModule)
  },
  {
    path: DOCUMENTS_ROUTE_PATH,
    loadChildren: () => import('./2-documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: AUTOGIRO_ROUTE_PATH,
    loadChildren: () => import('./3-autogiro/autogiro.module').then(m => m.AutogiroModule)
  },
  {
    path: LETTER_ROUTE_PATH,
    loadChildren: () => import('./4-letters/letters.module').then(m => m.LettersModule)
  },
  {
    path: LOAN_ROUTE_PATH,
    loadChildren: () => import('./5-loans/user.module').then(m => m.UserModule)
  },
  {
    path: KYC_ROUTE_PATH,
    loadChildren: () => import('./6-kyc/kyc.module').then(m => m.KycModule)
  },
  {
    path: '**',
    redirectTo: START_ROUTE_PATH
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
