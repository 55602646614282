import {LOCALE_ID, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {WINDOW_PROVIDERS} from './application/window.provider'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {SpbCommonModule} from './common/common.module'
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import '@angular/common/locales/global/fr'
import '@angular/common/locales/global/sv'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'

@NgModule({
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SpbCommonModule,
        ThemeModule], providers: [
        WINDOW_PROVIDERS,
        LOCAL_STORAGE_PROVIDERS,
        // This must be provided in "app" for reasons I do not understand
        {provide: LOCALE_ID, useValue: 'sv-SE'},
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                floatLabel: 'always',
                appearance: 'outline',
                color: 'primary',
                hideRequiredMarker: true,
                subscriptSizing: 'dynamic'
            }
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {
}
